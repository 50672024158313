import { City, Genre, ProJobTitle } from "./user";

export const slugify = (str: string): string =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-^&]/g, "-")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

export const JobSlug: { [P in string]: P } = {};

export const genreSlugify = (str: string): string => {
  return str.replace(/\//g, ", ");
};

Object.values(ProJobTitle).forEach((job) => {
  JobSlug[slugify(job)] = slugify(job);
});

export const GenreSlug: { [P in string]: P } = {};

Object.values(Genre).forEach((genre) => {
  GenreSlug[slugify(genre)] = slugify(genre);
});

export const CitySlug: { [P in string]: P } = {};

Object.values(City).forEach((city) => {
  CitySlug[slugify(city)] = slugify(city);
});

export enum FilterTypes {
  job = "job",
  genre = "genre",
  city = "city",
}

export interface DirectoryLink {
  title: string;
  link: string;
}

export interface DirectoryType {
  title?: string;
  breadcrumbs?: DirectoryLink[];
  metaTitle?: string;
  metaDescription?: string;
  links1?: DirectoryLink[];
  links2?: DirectoryLink[];
  detailTitle?: string;
  detailDescription?: string;
  query?: {
    [key: FilterTypes | string]: string;
  };
  children?: DirectoriesType;
}

export interface DirectoriesType {
  [key: string]: DirectoryType;
}
